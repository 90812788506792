@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
.App {
  text-align: center;
  font-family: "Bebas Neue", sans-serif;

}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app-container {
  margin-left: auto;
  margin-right: auto;
}
.news-card {
  min-width: 90vw !important;
}
#navbar {
  top: 0;
  z-index: 100;
  position: fixed;
  width: 100%;
}

.App-link {
  color: #61dafb;
}
.news-container {
  width: 75vw;
}

.socialFeed.grid .posts, .socialFeed.grid .reels {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  width: 100% !important;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  min-width: 75vw !important;
}

.extra-height {
  margin-top: 35% !important;
}

.center {
  justify-content: center;
}

.intro-container {
  margin-top: 12vh !important;
}

.Card {
  margin-bottom: 20px;
  z-index: 1 !important;
  width: 90%;
}

.news-title {
  min-width: 100% !important;
}
.logo {
  height: 50vh;
  width: 50vh;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 30px;
  background-color: #f5f5f5;
  z-index: 1000;
}

.intro {
  /*transform: translateX(-300px); */
  animation: intro 4s;
}

.navbar {
  z-index: 100;
}

.fade-in {
  animation: fadeIn 2s;
}
.kitty-header {
  height: 100% !important;
  object-fit: cover !important;
}

.main-page {
  overflow-y: auto;
  height: calc(100vh - 120px);
  margin-top: 60px;
}

.main-page::before {
  content: "";
  background-image: url('./pawbg.jpg');
  background-repeat: repeat;
  opacity: 0.02; /* Set the opacity (0.1 for 10%) */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place the pseudo-element behind the content */
}

.kitty-card-text {
  margin: 0 3rem !important;
}

.card-img-container {
  height: 25vh !important;
}

#news {
  text-align: left;
}

.news-img {
  max-height: 50% !important;
  max-width: 50%;
}

.address-text p,
.kitty-card-text li {
  font-size: .8em !important;
}

.news-text {
  font-size: 0.75em;
  line-height: 0.75em;
}

.card-date {
  font-size: 0.75em;
  color: gray;
}

.kitty-card-text ul {
  list-style-type: none;
  padding: 0;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes intro {
  0% {
    opacity: 0;
    transform: translateX(0px);
  }
  50% {
    opacity: 1;
    transform: translateX(0px);
  }
  /*
  100% {
    transform: translateX(-300px);
  }
  */
}

/* Adjust font size for small screens */
@media only screen and (max-width: 600px) {
  .kitty-card-text {
    margin: 0 0.5em !important;
  }
  .kitty-card-text h6 {
    font-size: 1em !important;
  }
  .kitty-card-text p {
    font-size: .8em !important;
  }
  .nav-brand {
    font-size: .8em !important;
  }
  .card-img-container {
    height: 15vh !important;
  }
  .logo {
    height: 40vh;
    width: 40vh;
  }
  .news-img {
    max-height: 100% !important;
    max-width: 100% !important;
  }
  .news-card {
    min-width: 100vw !important;
  }
}

@media only screen and (max-width: 1100px) {
  .news-img {
    height: 100% !important;
    width: 100%;
  }
}